<template>
  <PageListLayout
    icon="mdi-book-arrow-up"
    :title="title"
    subTitle="Biên bản nghiệm thu"
    @reset-filter="resetFilter"
  >
    <template slot="filter">
      <FilterTotal v-model="formSearch" />
    </template>
    <template slot="side-right">
      <div class="d-flex">
        <div class="mr-2">
          <v-tooltip bottom v-if="!isShowListTotal">
            <template v-slot:activator="{ on }">
              <v-btn @click="exportBienBan" icon v-on="on" >
                <v-icon> mdi-file-excel-outline </v-icon>
              </v-btn>
            </template>
            <span>Xuất biên bản nghiệm thu</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <Table
      @table-reset-filter="resetFilter"
      ref="tableBienBanNghiemThu"
      :isShowListTotal="isShowListTotal"
    />
    <DialogExport ref="exportBienBanNghiemThu"/>
  </PageListLayout>
</template>
<script>
import { debounce } from "lodash";
import FilterTotal from "./filterTotal";
import Table from "./table";
import DialogExport from "./DialogExport.vue";
export default {
  components: {  FilterTotal, Table, DialogExport },
  data() {
    return {
      formSearch: {},
      data: [],
      btnLoading: false,
      menu: {},
      loading: false,
      isShowListTotal: false,
    };
  },
  computed: {
    title() {
      return this.isShowListTotal
        ? "Tổng hợp biên bản nghiệm thu theo nhà cung cấp"
        : "Biên bản nghiệm thu";
    },
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function (val) {
        this.$refs.tableBienBanNghiemThu.getAllList(val);
      }, 300),
    },
  },
  methods: {
    resetFilter() {
      this.formSearch = this.$options.data.call(this).formSearch;
    },
    exportBienBan(){
      this.$refs.exportBienBanNghiemThu.showExport()
    },
    createItem() {
      if (!this.isShowListTotal) {
        this.$router.push({ path: "/kinhdoanh/themmoibienbannghiemthu" });
      } else {
        this.$refs.form.showFormAdd();
      }
    },
    editItem(item) {
      if (!this.isShowListTotal) {
        this.$router.push({
          path: `/kinhdoanh/chitietbienbannghiemthu/${item.id}`,
        });
      } else {
        this.$refs.form.showFormEdit(item);
      }
    },
  },
};
</script>
