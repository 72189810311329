<template>
  <v-dialog v-model="show" persistent width="800">
    <v-card>
      <v-card-title class="headline">XUẤT BIÊN BẢN NGHIỆM THU</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Ngày hoạch toán ( * Chọn khoảng thời gian)</div>
              <DateRangeComponent
                placeholder="Ngày hoạch toán"
                v-model="form.date_range"
                :rules="required"
                hideDetail="auto"
              />
              <!-- <MonthPicker
                v-model="form.date"
                :rules="required"
                hideDetail="auto"
                placeholder="Tháng"
              ></MonthPicker> -->
            </v-col>
            <v-col cols="6">
              <div class="label-form">Công ty bên A</div>
              <v-autocomplete
                v-model="form.ben_A"
                :items="khachhangs"
                placeholder="Công ty bên A"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="required"
                hide-details="auto"
                item-text="name"
                item-value="id"
                :loading="isLoadingKhachHang"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">Công ty bên B</div>
              <v-autocomplete
                v-model="form.ben_B"
                :items="companyList"
                placeholder="Công ty bên B"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="required"
                hide-details="auto"
                item-text="name"
                item-value="id"
                :loading="isLoadingCongTyB"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Đại diện bên B</div>
              <v-text-field
                v-model="form.dai_dien"
                placeholder="Đại diện bên B"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Chức vụ người đại diện</div>
              <v-text-field
                v-model="form.chuc_vu"
                placeholder="Chức vụ người đại diện bên B"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Người lập</div>
              <v-text-field
                v-model="form.nguoi_lap"
                placeholder="Người lập biên bản"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Đại diện phòng kế toán</div>
              <v-text-field
                v-model="form.phong_ke_toan"
                placeholder="Đại diện phòng kế toán"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="exportExcel">
          Xuất biên bản
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { TYPES } from "@/constants/bienbangiaonhan";
import MonthPicker from "@/components/Date/MonthPicker";
import KHACHHANG from "@/api/khachhang";
import APINT from "@/api/bienbannghiemthu.api";
import { getCompanyManagers } from "@/api/congty";
import { TYPE } from "@/constants/congtynhapkhau";
import { saveAs } from "file-saver";
import { getErrorMessage } from "@/utils/getErrorMessage";
import DateRangeComponent from "@/components/Date/date-range";
export default {
  props: {
    types: { type: Array, default: () => TYPES },
  },
  components: {
    DateRangeComponent,
  },
  mounted() {
    this.fetchKhachHang();
    this.getCompanyList();
  },
  data: () => ({
    show: false,
    form: {},
    required: [(v) => !!v || "Không thể bỏ trống"],
    isLoadingKhachHang: false,
    isLoadingCongTyB: false,
    khachhangs: [],
    btnLoading: false,
    companyList: [],
  }),
  computed: {},
  methods: {
    async exportExcel() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const res = await APINT.exportBienBan(this.form);
          if(res && res.isDone){
            saveAs(new Blob([res.data]), "Biên bản nghiệm thu.xls");
          }
        } catch (error) {
          this.$toast.error("Lỗi không xuất được biên bản nghiệm thu", {
            position: "top-center",
            timeout: 1500,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } finally {
          this.btnLoading = false;
        }
      }
    },
    showExport() {
      this.form = {
        dai_dien: "Vũ Văn Cường",
        chuc_vu: "Phó Tổng Giám Đốc",
        phong_ke_toan: "Đỗ Thế Anh",
      };
      this.show = true;
    },
    async getCompanyList() {
      try {
        this.isLoadingCongTyB = true;
        this.companyList = await getCompanyManagers({
          type: TYPE,
          isPaging: false,
        });
      } finally {
        this.isLoadingCongTyB = false;
      }
    },
    async fetchKhachHang() {
      try {
        this.isLoadingKhachHang = true;
        const res = await KHACHHANG.getAllCustomers({
          select: ["id", "name", "code"],
          in_bien_ban_nghiem_thu: true,
        });
        this.khachhangs = res.data;
      } finally {
        this.isLoadingKhachHang = false;
      }
    },
  },
};
</script>
