import request from "../utils/request";

export function getCompanyManagers(params) {
  return request({
    url: "companymanager",
    method: "get",
    params,
  });
}

export function getAllCompanyManagers() {
  return request({
    url: "allcompanymanager",
    method: "get",
  });
}

export function addCompanyManagers(data) {
  return request({
    url: "companymanager",
    method: "post",
    data,
  });
}
export function UpdateUploadImage(data) {
  return request({
    url: "update-image-companymanager",
    method: "post",
    data,
  });
}

export function editCompanyManagers(data) {
  return request({
    url: "companymanager",
    method: "put",
    data,
  });
}

export function deleteCompanyManagers(data) {
  return request({
    url: "/companymanager",
    method: "delete",
    data,
  });
}
